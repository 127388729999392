<template>
  <div>
    <div class="d-flex gap-r align-center justify-center">
      <div
        class="elevation-3 white text-center pb-5"
        style="width: 300px; border-radius: 10px"
      >
        <center>
          <v-btn elevation="2" fab style="margin-top: -30px" class="white"
            ><v-icon style="color: #fc3333">mdi-alert</v-icon></v-btn
          >
        </center>
        <h3 class="mt-5">Missing Transactions</h3>
        <h1 style="font-family: 'Poppins'">0</h1>
      </div>
      <div
        class="elevation-3 white text-center ml-5 pb-5"
        style="width: 300px; border-radius: 10px"
      >
        <center>
          <v-btn elevation="2" fab style="margin-top: -30px" class="white"
            ><v-icon style="color: #06bc4f">mdi-swap-horizontal</v-icon></v-btn
          >
        </center>
        <h3 class="mt-5">Total Transactions</h3>
        <h1 style="font-family: 'Poppins'">{{TRANSACTIONS.length}}</h1>
      </div>
      <div
        class="elevation-3 white text-center ml-5 pb-5"
        style="width: 300px; border-radius: 10px"
      >
        <center>
          <v-btn elevation="2" fab style="margin-top: -30px" class="white"
            ><v-icon style="color: #f3ba2f">mdi-shape</v-icon></v-btn
          >
        </center>
        <h3 class="mt-5">Uncategorized Data</h3>
        <h1 style="font-family: 'Poppins'">0</h1>
      </div>
    </div>

    <v-container class="mb-10 pa-5 white mt-10" v-if="TRANSACTIONS.length > 0">
      <div class="text-right mb-10 d-flex justify-end">
        <v-btn small white rounded class="teal--text white pl-5 pr-5"
          ><v-icon>mdi-plus</v-icon>Add Missing Transactions</v-btn
        >
        <export-excel :data="TRANSACTIONS"
          ><v-btn small rounded class="teal white--text ml-5"
            >Export Transactions</v-btn
          >
        </export-excel>
      </div>

      <v-simple-table class="no-lines-table">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">#</th>
              <th class="text-left">Exchange</th>
              <th class="text-left">Coin</th>
              <th class="text-left">Timestamp</th>
              <th class="text-left">Quantity</th>
              <th class="text-left">Amount (&#8377;)</th>
              <th class="text-left">TDS</th>
            </tr>
          </thead>
          <tbody class="no-lines-body">
            <tr
              v-for="(item, index) in TRANSACTIONS"
              :key="item.name"
              style="font-family: 'Poppins', sans-serif"
            >
              <td class="py-6">{{ index + 1 }}</td>
              <td>
                <div class="d-flex align-center">
                  <img
                    :src="getExchangeLogoUrl(item.exchange)"
                    class="rounded-circle"
                    width="40"
                    height="40"
                  />
                  <div class="ms-3">
                    <p class="ma-0">{{item.exchange}}</p>
                  </div>
                </div>
              </td>

              <td>
                <p >
                  <i class="cc mx-1" :class="item.coin"></i
                  >{{ item.coin }}
                </p>
                
              </td>
              <td>
                <p class="mb-0 fw-normal fs-4">{{ item.date }}</p>
              </td>
              <td>
                <p v-if="item.type == 'Buy'" class="green--text">
                  <v-icon class="green--text">mdi-menu-up</v-icon>
                  {{ item.quantity }}
                </p>
                <p v-else class="red--text">
                  <v-icon class="red--text">mdi-menu-down</v-icon>
                  {{ item.quantity }}
                </p>
              </td>
              <td>
                
                <p>&#8377; {{ item.total }}</p>
              </td>

              <td>
                <p>&#8377; {{ item.tds || 0 }}</p>
                
                <!-- <span
                  class="badge bg-light-dark text-light-success fw-semibold fs-2" style="text-transform: lowercase;" v-if="item.type.toLowerCase() == 'buy'"
                  ><b>{{item.type}}</b></span>
                  <span
                  class="badge bg-dark text-light-danger fw-semibold fs-2" v-else style="text-transform: lowercase;"
                  ><b>{{item.type}}</b></span> -->
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
    <v-container class="mb-10 pa-5 white mt-10 pt-10" v-else>
      <h3 class="text-center mt-10">No Transactions Found</h3>
      <p class="text-center mt-4 grey--text mb-10 pb-10">
        Connect your accounts to import transactions
      </p>
    </v-container>
    <div
      class="pa-5 elevation-3 d-flex gap-2 align-center justify-center pl-10 pr-10"
      style="
        position: fixed;
        bottom: 50px;
        right: 50px;
        background-color: #fff;
        border: 1px solid #4aabab;
        border-radius: 10px;
        z-index: 999;
      "
    >
      <div
        style="
          width: 48px;
          height: 48px;
          background-color: #4aabab;
          border-radius: 25px;
        "
        class="d-flex align-center justify-center"
      >
        <v-icon style="font-size: 32px" color="white">mdi-phone</v-icon>
      </div>

      <p class="ma-0 ml-5" @click="dialog_integrations = true">
        Call an expert now
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      // processedContent : [
      // {
      //   timestamp: "11/05/23 00:41",
      //   date: "11/05/23",
      //   time: "00:41",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "USDT purchased",
      //   in_coin: "USDT",
      //   in_quantity: "6.75",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "1.72",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "101.40131154",
      //   out_cost_basis: "",
      //   usd_in: "686.16",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "08/05/23 22:25",
      //   date: "08/05/23",
      //   time: "22:25",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "USDT purchased",
      //   in_coin: "USDT",
      //   in_quantity: "10",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "2.53",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "100.87082294",
      //   out_cost_basis: "",
      //   usd_in: "1011.22",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "07/05/23 04:21",
      //   date: "07/05/23",
      //   time: "04:21",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ETH sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ETH",
      //   out_quantity: "0.001",
      //   exchange: "Bitbns",
      //   fee: "0.47",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "185122.80701754",
      //   usd_in: "",
      //   usd_out: "184.66",
      // },
      // {
      //   timestamp: "07/05/23 04:14",
      //   date: "07/05/23",
      //   time: "04:14",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "XRP sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "XRP",
      //   out_quantity: "4.22",
      //   exchange: "Bitbns",
      //   fee: "0.49",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "45.10327953",
      //   usd_in: "",
      //   usd_out: "189.86",
      // },
      // {
      //   timestamp: "05/05/23 23:08",
      //   date: "05/05/23",
      //   time: "23:08",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "USDT purchased",
      //   in_coin: "USDT",
      //   in_quantity: "19.8",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "4.99",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "100.72042117",
      //   out_cost_basis: "",
      //   usd_in: "1999.24",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "05/05/23 23:02",
      //   date: "05/05/23",
      //   time: "23:02",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "BTC sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "BTC",
      //   out_quantity: "0.00017043",
      //   exchange: "Bitbns",
      //   fee: "1.25",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "2912050.4129537",
      //   usd_in: "",
      //   usd_out: "495.06",
      // },
      // {
      //   timestamp: "05/05/23 22:47",
      //   date: "05/05/23",
      //   time: "22:47",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "BTC purchased",
      //   in_coin: "BTC",
      //   in_quantity: "0.00017043",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "1.25",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "2926439.6949304",
      //   out_cost_basis: "",
      //   usd_in: "500",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "05/05/23 22:47",
      //   date: "05/05/23",
      //   time: "22:47",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "XRP purchased",
      //   in_coin: "XRP",
      //   in_quantity: "4.22",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.5",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "47.26099443",
      //   out_cost_basis: "",
      //   usd_in: "199.94",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "05/05/23 22:46",
      //   date: "05/05/23",
      //   time: "22:46",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "ETH purchased",
      //   in_coin: "ETH",
      //   in_quantity: "0.001",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.48",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "190683.29177057",
      //   out_cost_basis: "",
      //   usd_in: "191.16",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "05/05/23 01:23",
      //   date: "05/05/23",
      //   time: "01:23",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "19301",
      //   exchange: "Bitbns",
      //   fee: "0.05",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099103",
      //   usd_in: "",
      //   usd_out: "19.08",
      // },
      // {
      //   timestamp: "05/05/23 01:23",
      //   date: "05/05/23",
      //   time: "01:23",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "19412",
      //   exchange: "Bitbns",
      //   fee: "0.05",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099104",
      //   usd_in: "",
      //   usd_out: "19.19",
      // },
      // {
      //   timestamp: "05/05/23 01:22",
      //   date: "05/05/23",
      //   time: "01:22",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "20000",
      //   exchange: "Bitbns",
      //   fee: "0.05",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099098",
      //   usd_in: "",
      //   usd_out: "19.77",
      // },
      // {
      //   timestamp: "05/05/23 01:22",
      //   date: "05/05/23",
      //   time: "01:22",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "20000",
      //   exchange: "Bitbns",
      //   fee: "0.05",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099098",
      //   usd_in: "",
      //   usd_out: "19.77",
      // },
      // {
      //   timestamp: "05/05/23 01:22",
      //   date: "05/05/23",
      //   time: "01:22",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "20000",
      //   exchange: "Bitbns",
      //   fee: "0.05",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099098",
      //   usd_in: "",
      //   usd_out: "19.77",
      // },
      // {
      //   timestamp: "05/05/23 01:22",
      //   date: "05/05/23",
      //   time: "01:22",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "20000",
      //   exchange: "Bitbns",
      //   fee: "0.05",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099098",
      //   usd_in: "",
      //   usd_out: "19.77",
      // },
      // {
      //   timestamp: "05/05/23 01:22",
      //   date: "05/05/23",
      //   time: "01:22",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "20000",
      //   exchange: "Bitbns",
      //   fee: "0.05",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099098",
      //   usd_in: "",
      //   usd_out: "19.77",
      // },
      // {
      //   timestamp: "05/05/23 01:22",
      //   date: "05/05/23",
      //   time: "01:22",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "20000",
      //   exchange: "Bitbns",
      //   fee: "0.05",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099098",
      //   usd_in: "",
      //   usd_out: "19.77",
      // },
      // {
      //   timestamp: "05/05/23 01:22",
      //   date: "05/05/23",
      //   time: "01:22",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "20000",
      //   exchange: "Bitbns",
      //   fee: "0.05",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099098",
      //   usd_in: "",
      //   usd_out: "19.77",
      // },
      // {
      //   timestamp: "05/05/23 01:22",
      //   date: "05/05/23",
      //   time: "01:22",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "20000",
      //   exchange: "Bitbns",
      //   fee: "0.05",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099098",
      //   usd_in: "",
      //   usd_out: "19.77",
      // },
      // {
      //   timestamp: "05/05/23 01:21",
      //   date: "05/05/23",
      //   time: "01:21",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "SHIB purchased",
      //   in_coin: "SHIB",
      //   in_quantity: "198705",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.5",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "0.00100401",
      //   out_cost_basis: "",
      //   usd_in: "0",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "05/05/23 01:20",
      //   date: "05/05/23",
      //   time: "01:20",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "57451",
      //   exchange: "Bitbns",
      //   fee: "0.15",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099097",
      //   usd_in: "",
      //   usd_out: "56.79",
      // },
      // {
      //   timestamp: "05/05/23 01:20",
      //   date: "05/05/23",
      //   time: "01:20",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "57451",
      //   exchange: "Bitbns",
      //   fee: "0.15",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099097",
      //   usd_in: "",
      //   usd_out: "56.79",
      // },
      // {
      //   timestamp: "05/05/23 01:20",
      //   date: "05/05/23",
      //   time: "01:20",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "57451",
      //   exchange: "Bitbns",
      //   fee: "0.15",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099097",
      //   usd_in: "",
      //   usd_out: "56.79",
      // },
      // {
      //   timestamp: "05/05/23 01:20",
      //   date: "05/05/23",
      //   time: "01:20",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "57451",
      //   exchange: "Bitbns",
      //   fee: "0.15",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099097",
      //   usd_in: "",
      //   usd_out: "56.79",
      // },
      // {
      //   timestamp: "05/05/23 01:20",
      //   date: "05/05/23",
      //   time: "01:20",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "57451",
      //   exchange: "Bitbns",
      //   fee: "0.15",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099097",
      //   usd_in: "",
      //   usd_out: "56.79",
      // },
      // {
      //   timestamp: "05/05/23 01:20",
      //   date: "05/05/23",
      //   time: "01:20",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "57451",
      //   exchange: "Bitbns",
      //   fee: "0.15",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099097",
      //   usd_in: "",
      //   usd_out: "56.79",
      // },
      // {
      //   timestamp: "05/05/23 01:20",
      //   date: "05/05/23",
      //   time: "01:20",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "57451",
      //   exchange: "Bitbns",
      //   fee: "0.15",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099097",
      //   usd_in: "",
      //   usd_out: "56.79",
      // },
      // {
      //   timestamp: "05/05/23 01:20",
      //   date: "05/05/23",
      //   time: "01:20",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "57451",
      //   exchange: "Bitbns",
      //   fee: "0.15",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099097",
      //   usd_in: "",
      //   usd_out: "56.79",
      // },
      // {
      //   timestamp: "05/05/23 01:20",
      //   date: "05/05/23",
      //   time: "01:20",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "57451",
      //   exchange: "Bitbns",
      //   fee: "0.15",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099097",
      //   usd_in: "",
      //   usd_out: "56.79",
      // },
      // {
      //   timestamp: "05/05/23 01:20",
      //   date: "05/05/23",
      //   time: "01:20",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "57451",
      //   exchange: "Bitbns",
      //   fee: "0.15",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099097",
      //   usd_in: "",
      //   usd_out: "56.79",
      // },
      // {
      //   timestamp: "05/05/23 01:19",
      //   date: "05/05/23",
      //   time: "01:19",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ETH sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ETH",
      //   out_quantity: "0.0052",
      //   exchange: "Bitbns",
      //   fee: "2.47",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "189843.84037016",
      //   usd_in: "",
      //   usd_out: "984.72",
      // },
      // {
      //   timestamp: "05/05/23 01:19",
      //   date: "05/05/23",
      //   time: "01:19",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "ETH purchased",
      //   in_coin: "ETH",
      //   in_quantity: "0.0052",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "2.49",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "191534.62497602",
      //   out_cost_basis: "",
      //   usd_in: "998.46",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "05/05/23 01:10",
      //   date: "05/05/23",
      //   time: "01:10",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "TRX sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "TRX",
      //   out_quantity: "1",
      //   exchange: "Bitbns",
      //   fee: "0.02",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "6.93734336",
      //   usd_in: "",
      //   usd_out: "6.92",
      // },
      // {
      //   timestamp: "05/05/23 01:10",
      //   date: "05/05/23",
      //   time: "01:10",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "TRX sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "TRX",
      //   out_quantity: "10",
      //   exchange: "Bitbns",
      //   fee: "0.18",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "6.94035088",
      //   usd_in: "",
      //   usd_out: "69.23",
      // },
      // {
      //   timestamp: "05/05/23 01:10",
      //   date: "05/05/23",
      //   time: "01:10",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "TRX sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "TRX",
      //   out_quantity: "10",
      //   exchange: "Bitbns",
      //   fee: "0.18",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "6.94035088",
      //   usd_in: "",
      //   usd_out: "69.23",
      // },
      // {
      //   timestamp: "05/05/23 01:10",
      //   date: "05/05/23",
      //   time: "01:10",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "TRX sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "TRX",
      //   out_quantity: "10",
      //   exchange: "Bitbns",
      //   fee: "0.18",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "6.94035088",
      //   usd_in: "",
      //   usd_out: "69.23",
      // },
      // {
      //   timestamp: "05/05/23 01:10",
      //   date: "05/05/23",
      //   time: "01:10",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "TRX sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "TRX",
      //   out_quantity: "10",
      //   exchange: "Bitbns",
      //   fee: "0.18",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "6.94035088",
      //   usd_in: "",
      //   usd_out: "69.23",
      // },
      // {
      //   timestamp: "05/05/23 01:10",
      //   date: "05/05/23",
      //   time: "01:10",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "TRX sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "TRX",
      //   out_quantity: "10",
      //   exchange: "Bitbns",
      //   fee: "0.18",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "6.94035088",
      //   usd_in: "",
      //   usd_out: "69.23",
      // },
      // {
      //   timestamp: "05/05/23 01:10",
      //   date: "05/05/23",
      //   time: "01:10",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "TRX sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "TRX",
      //   out_quantity: "10",
      //   exchange: "Bitbns",
      //   fee: "0.18",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "6.94035088",
      //   usd_in: "",
      //   usd_out: "69.23",
      // },
      // {
      //   timestamp: "05/05/23 01:10",
      //   date: "05/05/23",
      //   time: "01:10",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "TRX sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "TRX",
      //   out_quantity: "10",
      //   exchange: "Bitbns",
      //   fee: "0.18",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "6.94035088",
      //   usd_in: "",
      //   usd_out: "69.23",
      // },
      // {
      //   timestamp: "05/05/23 01:09",
      //   date: "05/05/23",
      //   time: "01:09",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "TRX sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "TRX",
      //   out_quantity: "10",
      //   exchange: "Bitbns",
      //   fee: "0.18",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "6.94035088",
      //   usd_in: "",
      //   usd_out: "69.23",
      // },
      // {
      //   timestamp: "05/05/23 00:52",
      //   date: "05/05/23",
      //   time: "00:52",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ADA sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ADA",
      //   out_quantity: "0.3",
      //   exchange: "Bitbns",
      //   fee: "0.03",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "39.09774436",
      //   usd_in: "",
      //   usd_out: "11.7",
      // },
      // {
      //   timestamp: "05/05/23 00:52",
      //   date: "05/05/23",
      //   time: "00:52",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ADA sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ADA",
      //   out_quantity: "0.2",
      //   exchange: "Bitbns",
      //   fee: "0.02",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "39.09774436",
      //   usd_in: "",
      //   usd_out: "7.8",
      // },
      // {
      //   timestamp: "05/05/23 00:52",
      //   date: "05/05/23",
      //   time: "00:52",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ADA sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ADA",
      //   out_quantity: "1.5",
      //   exchange: "Bitbns",
      //   fee: "0.15",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "39.09774436",
      //   usd_in: "",
      //   usd_out: "58.5",
      // },
      // {
      //   timestamp: "05/05/23 00:52",
      //   date: "05/05/23",
      //   time: "00:52",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ADA sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ADA",
      //   out_quantity: "1.1",
      //   exchange: "Bitbns",
      //   fee: "0.11",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "39.10685805",
      //   usd_in: "",
      //   usd_out: "42.91",
      // },
      // {
      //   timestamp: "05/05/23 00:52",
      //   date: "05/05/23",
      //   time: "00:52",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ADA sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ADA",
      //   out_quantity: "1.5",
      //   exchange: "Bitbns",
      //   fee: "0.15",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "39.09774436",
      //   usd_in: "",
      //   usd_out: "58.5",
      // },
      // {
      //   timestamp: "05/05/23 00:52",
      //   date: "05/05/23",
      //   time: "00:52",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ADA sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ADA",
      //   out_quantity: "1.5",
      //   exchange: "Bitbns",
      //   fee: "0.15",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "39.09774436",
      //   usd_in: "",
      //   usd_out: "58.5",
      // },
      // {
      //   timestamp: "05/05/23 00:52",
      //   date: "05/05/23",
      //   time: "00:52",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ADA sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ADA",
      //   out_quantity: "2",
      //   exchange: "Bitbns",
      //   fee: "0.2",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "39.09774436",
      //   usd_in: "",
      //   usd_out: "78",
      // },
      // {
      //   timestamp: "05/05/23 00:50",
      //   date: "05/05/23",
      //   time: "00:50",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ADA sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ADA",
      //   out_quantity: "2.7",
      //   exchange: "Bitbns",
      //   fee: "0.27",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "39.09774436",
      //   usd_in: "",
      //   usd_out: "105.3",
      // },
      // {
      //   timestamp: "05/05/23 00:49",
      //   date: "05/05/23",
      //   time: "00:49",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ADA sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ADA",
      //   out_quantity: "3.6",
      //   exchange: "Bitbns",
      //   fee: "0.36",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "39.1005291",
      //   usd_in: "",
      //   usd_out: "140.41",
      // },
      // {
      //   timestamp: "05/05/23 00:39",
      //   date: "05/05/23",
      //   time: "00:39",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "ADA purchased",
      //   in_coin: "ADA",
      //   in_quantity: "3.6",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.36",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "39.70074813",
      //   out_cost_basis: "",
      //   usd_in: "143.28",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "05/05/23 00:39",
      //   date: "05/05/23",
      //   time: "00:39",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "ADA purchased",
      //   in_coin: "ADA",
      //   in_quantity: "3.6",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.36",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "39.70074813",
      //   out_cost_basis: "",
      //   usd_in: "143.28",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "05/05/23 00:39",
      //   date: "05/05/23",
      //   time: "00:39",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "ADA purchased",
      //   in_coin: "ADA",
      //   in_quantity: "3.6",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.36",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "39.70074813",
      //   out_cost_basis: "",
      //   usd_in: "143.28",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "05/05/23 00:39",
      //   date: "05/05/23",
      //   time: "00:39",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "ADA purchased",
      //   in_coin: "ADA",
      //   in_quantity: "3.6",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.36",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "39.70074813",
      //   out_cost_basis: "",
      //   usd_in: "143.28",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "05/05/23 00:38",
      //   date: "05/05/23",
      //   time: "00:38",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "TRX purchased",
      //   in_coin: "TRX",
      //   in_quantity: "20",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.36",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "7.040399",
      //   out_cost_basis: "",
      //   usd_in: "141.15",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "05/05/23 00:38",
      //   date: "05/05/23",
      //   time: "00:38",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "TRX purchased",
      //   in_coin: "TRX",
      //   in_quantity: "20",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.36",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "7.040399",
      //   out_cost_basis: "",
      //   usd_in: "141.15",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "05/05/23 00:38",
      //   date: "05/05/23",
      //   time: "00:38",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "TRX purchased",
      //   in_coin: "TRX",
      //   in_quantity: "41",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.73",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "7.04020437",
      //   out_cost_basis: "",
      //   usd_in: "289.36",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "05/05/23 00:38",
      //   date: "05/05/23",
      //   time: "00:38",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "SHIB purchased",
      //   in_coin: "SHIB",
      //   in_quantity: "287259",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.73",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "0.00100702",
      //   out_cost_basis: "",
      //   usd_in: "0",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "05/05/23 00:38",
      //   date: "05/05/23",
      //   time: "00:38",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "SHIB purchased",
      //   in_coin: "SHIB",
      //   in_quantity: "287259",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.73",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "0.00100702",
      //   out_cost_basis: "",
      //   usd_in: "0",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "05/05/23 00:33",
      //   date: "05/05/23",
      //   time: "00:33",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ETH sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ETH",
      //   out_quantity: "0.0019",
      //   exchange: "Bitbns",
      //   fee: "0.9",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "188819.41696346",
      //   usd_in: "",
      //   usd_out: "357.86",
      // },
      // {
      //   timestamp: "05/05/23 00:33",
      //   date: "05/05/23",
      //   time: "00:33",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ETH sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ETH",
      //   out_quantity: "0.0014",
      //   exchange: "Bitbns",
      //   fee: "0.67",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "188814.8943788",
      //   usd_in: "",
      //   usd_out: "263.68",
      // },
      // {
      //   timestamp: "05/05/23 00:33",
      //   date: "05/05/23",
      //   time: "00:33",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ETH sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ETH",
      //   out_quantity: "0.0011",
      //   exchange: "Bitbns",
      //   fee: "0.52",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "188817.49829118",
      //   usd_in: "",
      //   usd_out: "207.18",
      // },
      // {
      //   timestamp: "05/05/23 00:33",
      //   date: "05/05/23",
      //   time: "00:33",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ETH sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ETH",
      //   out_quantity: "0.0008",
      //   exchange: "Bitbns",
      //   fee: "0.38",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "188822.05513785",
      //   usd_in: "",
      //   usd_out: "150.68",
      // },
      // {
      //   timestamp: "05/05/23 00:33",
      //   date: "05/05/23",
      //   time: "00:33",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ETH sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ETH",
      //   out_quantity: "0.0006",
      //   exchange: "Bitbns",
      //   fee: "0.29",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "188805.34670008",
      //   usd_in: "",
      //   usd_out: "113",
      // },
      // {
      //   timestamp: "05/05/23 00:33",
      //   date: "05/05/23",
      //   time: "00:33",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ETH sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ETH",
      //   out_quantity: "0.0004",
      //   exchange: "Bitbns",
      //   fee: "0.19",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "188822.05513785",
      //   usd_in: "",
      //   usd_out: "75.34",
      // },
      // {
      //   timestamp: "05/05/23 00:33",
      //   date: "05/05/23",
      //   time: "00:33",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ETH sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ETH",
      //   out_quantity: "0.0007",
      //   exchange: "Bitbns",
      //   fee: "0.34",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "188814.8943788",
      //   usd_in: "",
      //   usd_out: "131.84",
      // },
      // {
      //   timestamp: "05/05/23 00:33",
      //   date: "05/05/23",
      //   time: "00:33",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ETH sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ETH",
      //   out_quantity: "0.0008",
      //   exchange: "Bitbns",
      //   fee: "0.38",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "188822.05513785",
      //   usd_in: "",
      //   usd_out: "150.68",
      // },
      // {
      //   timestamp: "05/05/23 00:21",
      //   date: "05/05/23",
      //   time: "00:21",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "ETH purchased",
      //   in_coin: "ETH",
      //   in_quantity: "0.0077",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "3.7",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "192139.13268776",
      //   out_cost_basis: "",
      //   usd_in: "1483.16",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "05/05/23 00:16",
      //   date: "05/05/23",
      //   time: "00:16",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "DOGE sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "DOGE",
      //   out_quantity: "5",
      //   exchange: "Bitbns",
      //   fee: "0.1",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "7.87769424",
      //   usd_in: "",
      //   usd_out: "39.29",
      // },
      // {
      //   timestamp: "05/05/23 00:11",
      //   date: "05/05/23",
      //   time: "00:11",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "DOGE sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "DOGE",
      //   out_quantity: "7",
      //   exchange: "Bitbns",
      //   fee: "0.14",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "7.85535267",
      //   usd_in: "",
      //   usd_out: "54.85",
      // },
      // {
      //   timestamp: "05/05/23 00:11",
      //   date: "05/05/23",
      //   time: "00:11",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "DOGE sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "DOGE",
      //   out_quantity: "7",
      //   exchange: "Bitbns",
      //   fee: "0.14",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "7.85535267",
      //   usd_in: "",
      //   usd_out: "54.85",
      // },
      // {
      //   timestamp: "05/05/23 00:11",
      //   date: "05/05/23",
      //   time: "00:11",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "DOGE sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "DOGE",
      //   out_quantity: "13",
      //   exchange: "Bitbns",
      //   fee: "0.26",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "7.85502217",
      //   usd_in: "",
      //   usd_out: "101.86",
      // },
      // {
      //   timestamp: "05/05/23 00:11",
      //   date: "05/05/23",
      //   time: "00:11",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "DOGE sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "DOGE",
      //   out_quantity: "3",
      //   exchange: "Bitbns",
      //   fee: "0.06",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "7.87635756",
      //   usd_in: "",
      //   usd_out: "23.57",
      // },
      // {
      //   timestamp: "05/05/23 00:10",
      //   date: "05/05/23",
      //   time: "00:10",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ADA sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ADA",
      //   out_quantity: "3.2",
      //   exchange: "Bitbns",
      //   fee: "0.32",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "39.1697995",
      //   usd_in: "",
      //   usd_out: "125.03",
      // },
      // {
      //   timestamp: "05/05/23 00:10",
      //   date: "05/05/23",
      //   time: "00:10",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ADA sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ADA",
      //   out_quantity: "3.2",
      //   exchange: "Bitbns",
      //   fee: "0.32",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "39.1697995",
      //   usd_in: "",
      //   usd_out: "125.03",
      // },
      // {
      //   timestamp: "05/05/23 00:10",
      //   date: "05/05/23",
      //   time: "00:10",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "66795",
      //   exchange: "Bitbns",
      //   fee: "0.17",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099403",
      //   usd_in: "",
      //   usd_out: "66.23",
      // },
      // {
      //   timestamp: "05/05/23 00:10",
      //   date: "05/05/23",
      //   time: "00:10",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "200387",
      //   exchange: "Bitbns",
      //   fee: "0.5",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00099402",
      //   usd_in: "",
      //   usd_out: "198.69",
      // },
      // {
      //   timestamp: "05/05/23 00:09",
      //   date: "05/05/23",
      //   time: "00:09",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "TRX sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "TRX",
      //   out_quantity: "10",
      //   exchange: "Bitbns",
      //   fee: "0.18",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "6.89924812",
      //   usd_in: "",
      //   usd_out: "68.82",
      // },
      // {
      //   timestamp: "05/05/23 00:09",
      //   date: "05/05/23",
      //   time: "00:09",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "TRX sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "TRX",
      //   out_quantity: "27",
      //   exchange: "Bitbns",
      //   fee: "0.47",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "6.8998422",
      //   usd_in: "",
      //   usd_out: "185.83",
      // },
      // {
      //   timestamp: "04/05/23 21:45",
      //   date: "04/05/23",
      //   time: "21:45",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "XRP sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "XRP",
      //   out_quantity: "3.11",
      //   exchange: "Bitbns",
      //   fee: "0.36",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "46.1700876",
      //   usd_in: "",
      //   usd_out: "143.23",
      // },
      // {
      //   timestamp: "04/05/23 21:45",
      //   date: "04/05/23",
      //   time: "21:45",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "XRP sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "XRP",
      //   out_quantity: "1.03",
      //   exchange: "Bitbns",
      //   fee: "0.12",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "46.17368664",
      //   usd_in: "",
      //   usd_out: "47.44",
      // },
      // {
      //   timestamp: "04/05/23 21:45",
      //   date: "04/05/23",
      //   time: "21:45",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ETH sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ETH",
      //   out_quantity: "0.001",
      //   exchange: "Bitbns",
      //   fee: "0.47",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "187558.89724311",
      //   usd_in: "",
      //   usd_out: "187.09",
      // },
      // {
      //   timestamp: "04/05/23 21:45",
      //   date: "04/05/23",
      //   time: "21:45",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "XRP sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "XRP",
      //   out_quantity: "1.38",
      //   exchange: "Bitbns",
      //   fee: "0.16",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "46.17340453",
      //   usd_in: "",
      //   usd_out: "63.56",
      // },
      // {
      //   timestamp: "04/05/23 21:44",
      //   date: "04/05/23",
      //   time: "21:44",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ETH sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ETH",
      //   out_quantity: "0.0003",
      //   exchange: "Bitbns",
      //   fee: "0.15",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "187535.50543024",
      //   usd_in: "",
      //   usd_out: "56.12",
      // },
      // {
      //   timestamp: "04/05/23 21:44",
      //   date: "04/05/23",
      //   time: "21:44",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "BTC sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "BTC",
      //   out_quantity: "2.20E-05",
      //   exchange: "Bitbns",
      //   fee: "0.16",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "2871601.2050144",
      //   usd_in: "",
      //   usd_out: "62.96",
      // },
      // {
      //   timestamp: "04/05/23 21:44",
      //   date: "04/05/23",
      //   time: "21:44",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "BTC sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "BTC",
      //   out_quantity: "6.59E-05",
      //   exchange: "Bitbns",
      //   fee: "0.48",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "2871601.2050144",
      //   usd_in: "",
      //   usd_out: "188.88",
      // },
      // {
      //   timestamp: "04/05/23 21:43",
      //   date: "04/05/23",
      //   time: "21:43",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "DOGE purchased",
      //   in_coin: "DOGE",
      //   in_quantity: "35",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.71",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "8.05614535",
      //   out_cost_basis: "",
      //   usd_in: "282.81",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 21:42",
      //   date: "04/05/23",
      //   time: "21:42",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "ADA purchased",
      //   in_coin: "ADA",
      //   in_quantity: "6.4",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.65",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "40.10130923",
      //   out_cost_basis: "",
      //   usd_in: "257.28",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 21:41",
      //   date: "04/05/23",
      //   time: "21:41",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "TRX purchased",
      //   in_coin: "TRX",
      //   in_quantity: "36",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.64",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "7.07010252",
      //   out_cost_basis: "",
      //   usd_in: "255.16",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 21:40",
      //   date: "04/05/23",
      //   time: "21:40",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "SHIB purchased",
      //   in_coin: "SHIB",
      //   in_quantity: "267182",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.68",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "0.00100803",
      //   out_cost_basis: "",
      //   usd_in: "0",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 21:40",
      //   date: "04/05/23",
      //   time: "21:40",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "XRP purchased",
      //   in_coin: "XRP",
      //   in_quantity: "5.52",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.65",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "46.98037515",
      //   out_cost_basis: "",
      //   usd_in: "259.98",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 21:40",
      //   date: "04/05/23",
      //   time: "21:40",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "ETH purchased",
      //   in_coin: "ETH",
      //   in_quantity: "0.0013",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.63",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "192956.07136006",
      //   out_cost_basis: "",
      //   usd_in: "251.47",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 21:40",
      //   date: "04/05/23",
      //   time: "21:40",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "BTC purchased",
      //   in_coin: "BTC",
      //   in_quantity: "8.79E-05",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.65",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "2950081.2863757",
      //   out_cost_basis: "",
      //   usd_in: "259.98",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 21:39",
      //   date: "04/05/23",
      //   time: "21:39",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "XRP sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "XRP",
      //   out_quantity: "1.87",
      //   exchange: "Bitbns",
      //   fee: "0.22",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "46.16889818",
      //   usd_in: "",
      //   usd_out: "86.12",
      // },
      // {
      //   timestamp: "04/05/23 21:39",
      //   date: "04/05/23",
      //   time: "21:39",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "XRP sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "XRP",
      //   out_quantity: "5.58",
      //   exchange: "Bitbns",
      //   fee: "0.65",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "46.16918641",
      //   usd_in: "",
      //   usd_out: "256.98",
      // },
      // {
      //   timestamp: "04/05/23 21:38",
      //   date: "04/05/23",
      //   time: "21:38",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "TRX sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "TRX",
      //   out_quantity: "12",
      //   exchange: "Bitbns",
      //   fee: "0.21",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "6.94987469",
      //   usd_in: "",
      //   usd_out: "83.19",
      // },
      // {
      //   timestamp: "04/05/23 21:38",
      //   date: "04/05/23",
      //   time: "21:38",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "TRX sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "TRX",
      //   out_quantity: "36",
      //   exchange: "Bitbns",
      //   fee: "0.63",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "6.94987469",
      //   usd_in: "",
      //   usd_out: "249.57",
      // },
      // {
      //   timestamp: "04/05/23 21:38",
      //   date: "04/05/23",
      //   time: "21:38",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ETH sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ETH",
      //   out_quantity: "0.0009",
      //   exchange: "Bitbns",
      //   fee: "0.43",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "187268.17042607",
      //   usd_in: "",
      //   usd_out: "168.12",
      // },
      // {
      //   timestamp: "04/05/23 21:37",
      //   date: "04/05/23",
      //   time: "21:37",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ETH sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ETH",
      //   out_quantity: "0.0009",
      //   exchange: "Bitbns",
      //   fee: "0.43",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "187268.17042607",
      //   usd_in: "",
      //   usd_out: "168.12",
      // },
      // {
      //   timestamp: "04/05/23 21:37",
      //   date: "04/05/23",
      //   time: "21:37",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "BTC sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "BTC",
      //   out_quantity: "8.90E-05",
      //   exchange: "Bitbns",
      //   fee: "0.66",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "2948382.1914336",
      //   usd_in: "",
      //   usd_out: "261.75",
      // },
      // {
      //   timestamp: "04/05/23 21:37",
      //   date: "04/05/23",
      //   time: "21:37",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "BTC sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "BTC",
      //   out_quantity: "2.97E-05",
      //   exchange: "Bitbns",
      //   fee: "0.22",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "2948388.8352496",
      //   usd_in: "",
      //   usd_out: "87.26",
      // },
      // {
      //   timestamp: "04/05/23 21:37",
      //   date: "04/05/23",
      //   time: "21:37",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "242445",
      //   exchange: "Bitbns",
      //   fee: "0.61",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.001",
      //   usd_in: "",
      //   usd_out: "241.84",
      // },
      // {
      //   timestamp: "04/05/23 21:37",
      //   date: "04/05/23",
      //   time: "21:37",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "242445",
      //   exchange: "Bitbns",
      //   fee: "0.61",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.001",
      //   usd_in: "",
      //   usd_out: "241.84",
      // },
      // {
      //   timestamp: "04/05/23 21:29",
      //   date: "04/05/23",
      //   time: "21:29",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "SHIB purchased",
      //   in_coin: "SHIB",
      //   in_quantity: "484890",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "1.23",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "0.00100802",
      //   out_cost_basis: "",
      //   usd_in: "0",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 21:29",
      //   date: "04/05/23",
      //   time: "21:29",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "TRX purchased",
      //   in_coin: "TRX",
      //   in_quantity: "49",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.87",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "7.04015472",
      //   out_cost_basis: "",
      //   usd_in: "345.82",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 21:29",
      //   date: "04/05/23",
      //   time: "21:29",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "XRP purchased",
      //   in_coin: "XRP",
      //   in_quantity: "7.45",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.88",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "46.80111801",
      //   out_cost_basis: "",
      //   usd_in: "349.53",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 21:29",
      //   date: "04/05/23",
      //   time: "21:29",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "ETH purchased",
      //   in_coin: "ETH",
      //   in_quantity: "0.0018",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.87",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "192956.49764478",
      //   out_cost_basis: "",
      //   usd_in: "348.19",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 21:28",
      //   date: "04/05/23",
      //   time: "21:28",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "BTC purchased",
      //   in_coin: "BTC",
      //   in_quantity: "0.00011868",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.88",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "2950073.4177364",
      //   out_cost_basis: "",
      //   usd_in: "350.98",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 21:26",
      //   date: "04/05/23",
      //   time: "21:26",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "SHIB sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "SHIB",
      //   out_quantity: "494791",
      //   exchange: "Bitbns",
      //   fee: "1.24",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "0.00100001",
      //   usd_in: "",
      //   usd_out: "493.56",
      // },
      // {
      //   timestamp: "04/05/23 21:25",
      //   date: "04/05/23",
      //   time: "21:25",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ETH sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ETH",
      //   out_quantity: "0.0025",
      //   exchange: "Bitbns",
      //   fee: "1.17",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "186546.36591479",
      //   usd_in: "",
      //   usd_out: "465.2",
      // },
      // {
      //   timestamp: "04/05/23 21:24",
      //   date: "04/05/23",
      //   time: "21:24",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "BTC sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "BTC",
      //   out_quantity: "0.00016935",
      //   exchange: "Bitbns",
      //   fee: "1.21",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "2853901.8049997",
      //   usd_in: "",
      //   usd_out: "482.1",
      // },
      // {
      //   timestamp: "04/05/23 21:24",
      //   date: "04/05/23",
      //   time: "21:24",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "TRX sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "TRX",
      //   out_quantity: "63",
      //   exchange: "Bitbns",
      //   fee: "1.09",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "6.90010741",
      //   usd_in: "",
      //   usd_out: "433.62",
      // },
      // {
      //   timestamp: "04/05/23 19:50",
      //   date: "04/05/23",
      //   time: "19:50",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "TRX purchased",
      //   in_coin: "TRX",
      //   in_quantity: "63",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "1.12",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "7.0501524",
      //   out_cost_basis: "",
      //   usd_in: "445.26",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 19:33",
      //   date: "04/05/23",
      //   time: "19:33",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "SHIB purchased",
      //   in_coin: "SHIB",
      //   in_quantity: "494791",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "1.25",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "0.00100801",
      //   out_cost_basis: "",
      //   usd_in: "0",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 19:33",
      //   date: "04/05/23",
      //   time: "19:33",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "ETH purchased",
      //   in_coin: "ETH",
      //   in_quantity: "0.0025",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "1.21",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "192953.6159601",
      //   out_cost_basis: "",
      //   usd_in: "483.46",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 19:32",
      //   date: "04/05/23",
      //   time: "19:32",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "BTC purchased",
      //   in_coin: "BTC",
      //   in_quantity: "0.00016935",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "1.25",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "2945043.6501012",
      //   out_cost_basis: "",
      //   usd_in: "499.98",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 19:31",
      //   date: "04/05/23",
      //   time: "19:31",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "TRX sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "TRX",
      //   out_quantity: "85",
      //   exchange: "Bitbns",
      //   fee: "1.47",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "6.89995577",
      //   usd_in: "",
      //   usd_out: "585.03",
      // },
      // {
      //   timestamp: "04/05/23 19:30",
      //   date: "04/05/23",
      //   time: "19:30",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ADA sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ADA",
      //   out_quantity: "4.7",
      //   exchange: "Bitbns",
      //   fee: "0.46",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "38.99962673",
      //   usd_in: "",
      //   usd_out: "182.84",
      // },
      // {
      //   timestamp: "04/05/23 19:30",
      //   date: "04/05/23",
      //   time: "19:30",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ETH sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ETH",
      //   out_quantity: "0.0028",
      //   exchange: "Bitbns",
      //   fee: "1.33",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "189820.98102399",
      //   usd_in: "",
      //   usd_out: "530.17",
      // },
      // {
      //   timestamp: "04/05/23 19:30",
      //   date: "04/05/23",
      //   time: "19:30",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "BTC sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "BTC",
      //   out_quantity: "0.00015241",
      //   exchange: "Bitbns",
      //   fee: "1.11",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "2899249.9620549",
      //   usd_in: "",
      //   usd_out: "440.77",
      // },
      // {
      //   timestamp: "04/05/23 19:30",
      //   date: "04/05/23",
      //   time: "19:30",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ADA sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ADA",
      //   out_quantity: "5.1",
      //   exchange: "Bitbns",
      //   fee: "0.5",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "38.99945943",
      //   usd_in: "",
      //   usd_out: "198.4",
      // },
      // {
      //   timestamp: "04/05/23 18:38",
      //   date: "04/05/23",
      //   time: "18:38",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "TRX purchased",
      //   in_coin: "TRX",
      //   in_quantity: "85",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "1.5",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "7.04004694",
      //   out_cost_basis: "",
      //   usd_in: "599.9",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 18:26",
      //   date: "04/05/23",
      //   time: "18:26",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "ADA purchased",
      //   in_coin: "ADA",
      //   in_quantity: "9.8",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.99",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "40.05089318",
      //   out_cost_basis: "",
      //   usd_in: "393.47",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 18:16",
      //   date: "04/05/23",
      //   time: "18:16",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "ETH purchased",
      //   in_coin: "ETH",
      //   in_quantity: "0.0027",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "1.31",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "192840.12191743",
      //   out_cost_basis: "",
      //   usd_in: "521.96",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 18:16",
      //   date: "04/05/23",
      //   time: "18:16",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "ETH purchased",
      //   in_coin: "ETH",
      //   in_quantity: "0.0001",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.05",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "192917.70573566",
      //   out_cost_basis: "",
      //   usd_in: "19.33",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 18:16",
      //   date: "04/05/23",
      //   time: "18:16",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "BTC purchased",
      //   in_coin: "BTC",
      //   in_quantity: "0.00015241",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "1.13",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "2945002.8233006",
      //   out_cost_basis: "",
      //   usd_in: "449.97",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "04/05/23 18:15",
      //   date: "04/05/23",
      //   time: "18:15",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "DOGE sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "DOGE",
      //   out_quantity: "50",
      //   exchange: "Bitbns",
      //   fee: "0.99",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "7.91899749",
      //   usd_in: "",
      //   usd_out: "394.96",
      // },
      // {
      //   timestamp: "04/05/23 18:14",
      //   date: "04/05/23",
      //   time: "18:14",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "XRP sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "XRP",
      //   out_quantity: "8.62",
      //   exchange: "Bitbns",
      //   fee: "1",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "46.15948223",
      //   usd_in: "",
      //   usd_out: "396.9",
      // },
      // {
      //   timestamp: "04/05/23 18:14",
      //   date: "04/05/23",
      //   time: "18:14",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "ETH sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "ETH",
      //   out_quantity: "0.0031",
      //   exchange: "Bitbns",
      //   fee: "1.48",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "189997.57458162",
      //   usd_in: "",
      //   usd_out: "587.52",
      // },
      // {
      //   timestamp: "04/05/23 18:13",
      //   date: "04/05/23",
      //   time: "18:13",
      //   trade_pair: "",
      //   type: "SELL",
      //   desc: "BTC sold",
      //   in_coin: "",
      //   in_quantity: "",
      //   out_coin: "BTC",
      //   out_quantity: "0.00020713",
      //   exchange: "Bitbns",
      //   fee: "1.48",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "",
      //   out_cost_basis: "2851090.4548582",
      //   usd_in: "",
      //   usd_out: "589.07",
      // },
      // {
      //   timestamp: "03/05/23 21:14",
      //   date: "03/05/23",
      //   time: "21:14",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "DOGE purchased",
      //   in_coin: "DOGE",
      //   in_quantity: "50",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "0.99",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "7.91002494",
      //   out_cost_basis: "",
      //   usd_in: "396.49",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "03/05/23 21:13",
      //   date: "03/05/23",
      //   time: "21:13",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "XRP purchased",
      //   in_coin: "XRP",
      //   in_quantity: "8.62",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "1",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "46.24054712",
      //   out_cost_basis: "",
      //   usd_in: "399.59",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "03/05/23 21:13",
      //   date: "03/05/23",
      //   time: "21:13",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "ETH purchased",
      //   in_coin: "ETH",
      //   in_quantity: "0.0031",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "1.46",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "188367.79020192",
      //   out_cost_basis: "",
      //   usd_in: "585.4",
      //   usd_out: "",
      // },
      // {
      //   timestamp: "03/05/23 21:10",
      //   date: "03/05/23",
      //   time: "21:10",
      //   trade_pair: "",
      //   type: "BUY",
      //   desc: "BTC purchased",
      //   in_coin: "BTC",
      //   in_quantity: "0.00020713",
      //   out_coin: "",
      //   out_quantity: "",
      //   exchange: "Bitbns",
      //   fee: "1.5",
      //   fee_coin: "",
      //   tds_amount: "",
      //   tds_coin: "",
      //   in_cost_basis: "2889459.5934246",
      //   out_cost_basis: "",
      //   usd_in: "599.98",
      //   usd_out: "",
      // },
      // ]
    };
  },
  computed: {
    ...mapGetters(["TRANSACTIONS"]),
    transactionStyle(type) {
      if (type.toLowerCase() == "buy" || type.toLowerCase() == "deposit") {
        return "green--text";
      } else {
        return "red--text";
      }
    },
  },
  mounted() {
    // var transactions = 
    // this.$store.dispatch("SET_TRANSACTIONS", this.processedContent);
    // this.runCalculations();
  },
  methods : {
    getExchangeLogoUrl(exchangeName) {
      return require(`@/assets/icons/${exchangeName.toLowerCase().replace(" ",'')}.png`);
    },
  }
};
</script>

<style  scoped>
.text-light-success {
  color: #008b38;
}
.text-light-danger {
  color: #ef0000;
}
p {
  margin: 0px;
}
no-lines-table table,
.no-lines-table tbody,
.no-lines-table tr,
.no-lines-table td {
  border: none !important;
}

.no-lines-row {
  border-bottom: none !important;
}
</style>

